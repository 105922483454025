import { store } from "@/store/store";
import { eventBus } from "@/eventBus";
import { mapActions, mapGetters, mapMutations } from "vuex";
import imageCompression from 'browser-image-compression';

import firebase from "firebase/app";
import "firebase/auth";

const Utility = {
  // mixins: [FirebaseActions],
  computed: {
    ...mapGetters(["appConfig", "currentBrowser", "subscriberid"]),
  },
  methods: {
    ...mapActions(["fcmMessagingUnsubscribeToTopic"]),
    ...mapMutations(["setFirebaseUserUID"]),

    validName(name) {
      var regEx = /^[a-zA-Z ]{2,32}$/;
      return regEx.test(name);
    },
    validateName(name) {
      this.formError = null;
      if (!name) {
        this.formError = this.$t("Name Required");
        return false;
      }
      // else if (!this.validName(name)) {
      //   this.formError = this.$t("Name should have minimum 2 and maximum 32 characters");
      //   return false;
      // }
      return true;
    },
    validEmail(email) {
      /* eslint-disable */
      let regx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return regx.test(String(email).toLowerCase());
    },
    validateEmail(email) {
      this.formError = null;
      if (!email) {
        this.formError = this.$t("Please fill out this field");
        return false;
      } else if (!this.validEmail(email)) {
        this.formError = this.$t("Please enter only Latin letters or numbers, Ex: Abc,123");
        return false;
      }
      return true;
    },

    validateCountryCode() {
      this.formError = null;
      if(!this.country) {
        this.formError = "The Country code could not be fetched! Please try after sometime.";
        return false;
      }

      return true;
    },

    validateMobileno(mobile) {
      this.formError = null;
      if (!mobile) {
        this.formError = this.$t("Mobileno Required");
        return false;
      }
      return true;
    },
    validateEmailOrMobile(userInput) {
      this.formError = null;
      if (!userInput) {
        this.formError = this.$t("Email or Mobile required");
        return false;
      }
      return true;
    },
    validatePassword(password) {
      this.formError = null;
      if (!password) {
        this.formError = this.$t("Password Required!");
        return false;
      } else if (password.length < 6) {
        this.formError = this.$t("Password should be minimum 6 characters");
        return false;
      } else if (password.length > 16) {
        this.formError = this.$t("Password should be maximum 16 characters");
        return false;
      }

      return true;
    },
    validateOldPassword(password) {
      if (!password) {
        this.formError = this.$t("Old Password Required!");
        return false;
      } else if (password.length < 6) {
        this.formError = this.$t("Password should be minimum 6 characters");
        return false;
      } else if (password.length > 16) {
        this.formError = this.$t("Password should be maximum 16 characters");
        return false;
      }

      return true;
    },
    validateDOB(age) {
      this.formError = null;
      if (!age) {
        this.formError = this.$t("Age is Mandatory");
        return false;
      } else if (!this.appConfig.featureEnabled.kidsMode && this.kidsProfile) {
        // this.formError = "Age Should Be Greater Than 13";
        this.formError = this.$t("Age range is mandatory");

        return false;
      }
      return true;
    },
    validateMaxLength(amt, len) {
      if (amt.length >= len) {
        event.preventDefault();
        return false;
      }
      return true;
    },
    pinFunctionality(IdName) {
      var container = IdName;
      let check = true;
      container.onkeypress = function(e) {
        if (!((e.keyCode >= 48 && e.keyCode <= 57) || e.keyCode == 43 || e.keyCode == 16) || e.keyCode == 32) {
          // e.returnValue = false;
          check = false;
          e.preventDefault();

          return;
        } else {
          check = true;
        }
      };
      if (check) {
        container.onkeyup = function(e) {
          var target = e.srcElement || e.target;
          var maxLength = parseInt(target.attributes["maxlength"].value, 10);
          var myLength = target.value.length;
          if (myLength >= maxLength) {
            var next = target;
            while ((next = next.nextElementSibling)) {
              if (next == null) break;
              if (next.tagName.toLowerCase() === "input") {
                next.focus();
                break;
              }
            }
          }
          // Move to previous field if empty (user pressed backspace)
          else if (myLength === 0) {
            var previous = target;
            while ((previous = previous.previousElementSibling)) {
              if (previous == null) break;
              if (previous.tagName.toLowerCase() === "input") {
                previous.focus();
                break;
              }
            }
          }
        };
      }
    },
    toggleEye(_event) {
      // Get the target element
      let target = _event.target;
      // Get the container div of the input field
      let parentNode = target.parentNode.parentNode;
      // Get the input element from it
     
      let inputElm = parentNode.getElementsByTagName("input")[0];

      if(inputElm === undefined){
        inputElm = parentNode.parentNode.getElementsByTagName("input")[0];
      }

      // Now change the type to text if password or vice-versa and change the icon
      if (inputElm.type === "password") {
        inputElm.type = "text";
        // Change the icon
        target.src = this.eyeClose;
      } else {
        inputElm.type = "password";
        // Change the icon
        target.src = this.eyeopen;
      }
    },
    collectionsToArray(collections) {
      let arr = [];
      for (var i in collections) {
        let obj = {
          ...collections[i],
        };
        arr.push(obj);
      }
      return arr;
    },
    logoutCB() {
      eventBus.$emit("enableOverlayLoader", true);
      try {
        const subscriberDetails = JSON.parse(localStorage.getItem("subscriberDetails"));
        //removing subscriber details from localstorage.
        localStorage.removeItem("subscriberDetails");
        localStorage.removeItem("isMultipleProfileEnabled");
        //removing config details from localstorage.
        // localStorage.removeItem("appConfig");
        //removing loginType from localstorage.
        localStorage.removeItem("loginType");

        localStorage.removeItem("sessionToken");

        localStorage.removeItem("currentProfileIdAdminCheck");

      
        localStorage.removeItem("login-payload");

        localStorage.removeItem("currentProfileId");

        localStorage.removeItem("subscriberProfileDetails");

        localStorage.removeItem("subscriptionDetails");

        localStorage.removeItem("offerCoupon");
        localStorage.removeItem("refreshtoken");
        localStorage.removeItem("getUserPasswordNumber");
        clearInterval(window.myIntervalreFreshToken);

        localStorage.removeItem("parentalControlEnabled")
        
        //commiting appconfig state after removed from localstorage.
        store.commit("commitappconfig");
        //commiting subscriberdetails state after removed from localstorage.
        store.commit("commitsubscriberdetails");
        //commiting setToken state after removed from localstorage.
        store.commit("setToken");
        //routing back to home page.

        // Unsubscribe from USER ID
        this.fcmMessagingUnsubscribeToTopic(this.subscriberid);

        // Unsubscribe from Country
        this.fcmMessagingUnsubscribeToTopic(subscriberDetails.country);

        eventBus.$emit("enableOverlayLoader", false);

        firebase
            .auth()
            .signOut()
            .then(() => {});

            this.firebaseRemoveUser()
                .then(() => {
                  firebase
                    .auth()
                    .signOut()
                    .then((data) => {});
                });

                this.setFirebaseUserUID(null);

                setTimeout(() => {
                  location.reload();
                }, 1000)

      //   setTimeout(() => {
      //     firebase
      //       .auth()
      //       .signOut()
      //       .then(() => {
      //         this.$router.push("/");
      //         eventBus.$emit("enableOverlayLoader", false);

      //         this.$router.go(0);
      //       })
      //       .catch((err) => {
      //         this.$router.push("/");
      //         eventBus.$emit("enableOverlayLoader", false);

      //         this.setFirebaseUserUID(null);

      //         this.$router.go(0);
      //       });
      //   }, 1500);

      //   this.firebaseRemoveUser()
      //     .then(() => {
      //       firebase
      //         .auth()
      //         .signOut()
      //         .then((data) => {
      //           this.$router.push("/");
      //           eventBus.$emit("enableOverlayLoader", false);

      //           this.setFirebaseUserUID(null);

      //           this.$router.go(0);
      //           // location.reload();
      //         })
      //         .catch(() => {
      //           this.$router.push("/");
      //           eventBus.$emit("enableOverlayLoader", false);

      //           this.setFirebaseUserUID(null);

      //           this.$router.go(0);

      //           // location.reload();
      //         });
      //     })
      //     .catch(() => {
      //       eventBus.$emit("enableOverlayLoader", false);

      //       this.setFirebaseUserUID(null);

      //       this.$router.go("/");

      //       // location.reload();
      //     });
      } catch (err) {
        // // this.$router.push("/");
        // eventBus.$emit("enableOverlayLoader", false);

        // location.reload();

        // // this.$router.go("/");
        setTimeout(() => {
          location.reload();
        }, 1000)
      }
    },
    snackbarMessage(message, data) {
      let snack = {
        status: data,
        message: message,
      };
      eventBus.$emit("snackBarMessage", snack);
      return false;
    },
    displayDateFormat(val) {
      let convert = null;

      if (val) {
        convert = new Date(val);
      } else {
        convert = new Date();
      }
      const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
      let currentDatetime = convert;

      let date = currentDatetime.getDate();

      if (date < 10) {
        date = "0" + date;
      }

      let d = date + " " + months[currentDatetime.getMonth()] + " " + currentDatetime.getFullYear();
      return d;
    },
    getCurrentDate(age) {
      let d = new Date();
      const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

      let dd = d.getDate();
      let mm = months[d.getMonth()];
      let yyyy = d.getFullYear();

      if (dd < 10) dd = "0" + dd;
      if (mm < 10) mm = "0" + (mm + 1);

      if (age) yyyy = yyyy - age;

      // return dd + " " + mm + " " + yyyy;

      return yyyy + "-" + mm + "-" + dd;
    },
    isImage(file) {

      if(file.type.match('image.*'))
        return true;
    
      // if(file.type.match('video.*'))
      //   return 'video';
    
      // if(file.type.match('audio.*'))
      //   return 'audio';
    
      // etc...
    
      return false;
    },
    async attachFile(event) {
      let uploadedFile = event.target.files[0];
      let fileExisted = false;
      console.log("Hello", this.isImage(uploadedFile));
      let fileSize = Math.round(uploadedFile.size / 1000);
      this.imagesUploadedArr.forEach((image) => {
        if (image.name === uploadedFile.name) {
          this.formError = this.$t("Same file is already attached!");
          fileExisted = true;
        }
      });
      if(!fileExisted){
      if(this.isImage(uploadedFile)){
        if (fileSize > 1000) {
          const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
          }
          try {
            this.showLoader = true;
            uploadedFile = await imageCompression(uploadedFile, options);
            //console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
            console.log('compressedFile-size', uploadedFile, `${uploadedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
    
            // uploadToServer(compressedFile); // write your own logic
          } catch (error) {
            console.log(error);
            this.formError = this.$t("Compression Error : "+error);
          }
          // this.formError = "Image size should less than 1 MB";
          // return;
        }
      }else {
        if(fileSize > 1000){
          this.formError = "File size should less than 1 MB";
          return;
        }
      }
    } else {
      return;
    }
      if (this.formError) {
        event.target.value = null;
        this.showLoader = false;
        return;
      } else {
        this.imagesUploadedArr.push(uploadedFile);
        this.showLoader = false;
        event.target.value = null;
      }
    },

    getCategoryName(category) {
      let categoryName;

      this.appConfig.ticketCategories.forEach((ele) => {
        if (ele.apiCategory === category) {
          categoryName = this.localDisplayLang === "ara" ? ele.categoryTitle.ar : ele.categoryTitle.en;
        }
      });

      return categoryName;
    },

    getDate(currentDate) {
      if (currentDate) {
        return new Date(currentDate);
      } else {
        return new Date();
      }
    },

    getTodaysDate() {
      return new Date(Date.now());
    },

    getDateForPlayback(currentDate) {
      if (currentDate) {
        return new Date(
          `${new Date(currentDate).getFullYear()}/${new Date(currentDate).getMonth() + 1}/${new Date(
            currentDate
          ).getDate()} 23:59:59 UTC`
        );
      } else {
        return false;
      }
    },

    calcTimeFormat(val) {
      let totalTime = val;
      let hours = Math.floor(totalTime / 3600);
      let minutes = Math.floor((totalTime - hours * 3600) / 60);

      let showTime =
        (hours ? hours.toString().padStart(2, "0") +  this.$t("hr") : "") +
        " " +
        (minutes ? minutes.toString().padStart(2, "0") + (minutes === 1 ? this.$t("min") : this.$t("mins")) : "");

      if (showTime == " ") {
        return val + this.$t("secs");
      } else {
        return showTime;
      }
    },
    secondsToTime(e) {
      let h = Math.floor(e / 3600).toString().padStart(2,'0') 
      let m = Math.floor(e % 3600 / 60).toString().padStart(2,'0')
      let s = Math.floor(e % 60).toString().padStart(2,'0');
  
     h = h != '00' ? h+'h' : '';  
    m = m != '00' ? m+'m' : '';
      
      return h + ' ' + m + ' ' + s + 's';
   },
    //fetching suitable content_details from each content object
    fetchContentDetails(content_details) {
      let temporaryVideoTag = document.createElement("video");

      let isAndroid = navigator.userAgent.includes("Android");

      let index = content_details.findIndex((element) => {
        if (!isAndroid && temporaryVideoTag.canPlayType("application/vnd.apple.mpegurl") != "") {
          return element.drmscheme[0] === "FAIRPLAY";
        } else {
          return element.drmscheme[0] === "WIDEVINE";
        }
      });

      if (index === -1) {
        return content_details[
          content_details.findIndex((element) => {
            return element.drmscheme[0] === "NONE";
          })
        ];
      } else if (index > -1) {
        return content_details[index];
      }
    },
    //fetching suitable availability from availability set.
    fetchAvailabilityId(content_details) {
      content_details.availabilityset.forEach((availability) => {
        let index = this.availabilities.findIndex((element) => {
          return element.availabilityid === availability;
        });

        if (index > -1) {
          this.pricemodel.push(this.availabilities[index].pricemodel);
          this.filteredAvailabilities.push(this.availabilities[index]);
        }
      });
    },
    //check availability from different places.
    checkAvailability(content) {
      this.filteredAvailabilities = [];
      if (content.hasOwnProperty("contentdetails") && content.contentdetails.length > 0) {
        let content_details = this.fetchContentDetails(content.contentdetails);

        let isContentPlayable = false;

        this.fetchAvailabilityId(content_details);

        this.filteredAvailabilities.forEach((element) => {
          //check avalabilty belongs to subscribed list.
          if (element.pricemodel === "PLAN") {
            if (this.subscriberid && this.subscriptions.length > 0) {
              this.subscriptions.forEach((subscription) => {
                let value = subscription.availabilityset.includes(element.availabilityid);
                if (value && !isContentPlayable) {
                  content.isDrmContent = true;
                  content.videoType = "Content";
                  content.playback_details = {
                    packageid: content_details.packageid,
                    availabilityid: element.availabilityid,
                    drmscheme: content_details.drmscheme[0],
                  };
                  isContentPlayable = true;
                }
              });
            }
          }
          //check availability belongs to purchased list.
          else if (element.pricemodel === "PAID" || element.pricemodel === "RENTAL") {
            if (this.subscriberid && this.purchases.length > 0) {
              //write purchase check logic.
              this.purchases.forEach((purchase) => {
                if (purchase.availabilityid === element.availabilityid && content.objectid === purchase.objectid) {
                  if (!isContentPlayable) {
                    console.log("matched with purchase");
                    content.isDrmContent = true;
                    content.videoType = "Content";
                    content.playback_details = {
                      packageid: content_details.packageid,
                      availabilityid: element.availabilityid,
                      drmscheme: content_details.drmscheme[0],
                    };
                    isContentPlayable = true;
                  }
                }
              });
            }
          }
          //check availability belongs to free or adbased pricemodel.
          else if (element.pricemodel === "FREE" || element.pricemodel === "ADBASED") {
            if (!isContentPlayable) {
              content.isDrmContent = true;
              content.videoType = "Content";
              //creating this playback_details for content playback.
              content.playback_details = {
                packageid: content_details.packageid,
                availabilityid: element.availabilityid,
                drmscheme: content_details.drmscheme[0],
              };
            }
          }
        });
      } else if (content.hasOwnProperty("trailer") && content.trailer.length > 0) {
        content.isDrmContent = true;
        content.videoType = "Trailer";
      }

      if (content.videoType) {
        let payload = {
          content: content,
          state: true,
        };
        // eventBus.$emit("togglePlayer", payload);
      } else {
        let payload = {};

        //check for filteredAvailabilities length.
        if (this.filteredAvailabilities && this.filteredAvailabilities.length === 1) {
          this.filteredAvailabilities.forEach((element) => {
            if (element.pricemodel === "PAID" || element.pricemodel === "RENTAL") {
              payload.listType = "onlyPurchase";
            } else if (element.pricemodel === "PLAN") {
              payload.listType = "onlySubscribe";
            }
          });
        } else if (this.filteredAvailabilities && this.filteredAvailabilities.length > 1) {
          payload.listType = "Subscribe/Purchase";
        }

        payload.availabilities = this.filteredAvailabilities;
        payload.content = content;
        // eventBus.$emit("subscribePopup", payload);
        let currentLanguage = this.getCurrentLanguageUrlBase();
        this.$router.push({name: 'seeAllPlans', params: {lang: currentLanguage}});

        //here write logic for subscription or purchasing a content.
      }
    },

    getDeviceOS() {
      let deviceOS = "";

      if (navigator.userAgent.indexOf("Win") != -1) {
        deviceOS = "WINDOWS";
      }

      if (navigator.userAgent.indexOf("Macintosh") != -1) {
        deviceOS = "MACOS";
      }

      if (navigator.userAgent.indexOf("Linux") != -1) {
        deviceOS = "LINUX";
      }

      if (navigator.userAgent.indexOf("Android") != -1) {
        deviceOS = "ANDROID";
      }

      if (navigator.userAgent.indexOf("like Mac") != -1) {
        deviceOS = "MACOS";
      }

      if(!deviceOS) {
        deviceOS = "OTHER";
      }
      return deviceOS;
    },
    getFreeTrial() {
      console.log("get free trial called");
      let free_trial = "";
      if (this.paymentDetail && this.paymentDetail.detail) {
        if (this.paymentDetail.detail.trialperioddays > 1) {
          free_trial = true;
        } else {
          free_trial = false;
        }
      }
      return free_trial;
    },
    checkSource() {
      let source;
      if (this.$route.name == "Home") {
        source = "Home-Subscriptions";
      } else {
        source = "Profile";
      }
      return source;
    },
    getPlatform() {
      let platform;
      let isMobile = RegExp(/Android|webOS|iPhone|iPod|iPad|BlackBerry|IEMobile|Opera Mini/i).test(navigator.userAgent);

      if (!isMobile) {
        const isMac = RegExp(/Macintosh/i).test(navigator.userAgent);

        if (isMac && navigator.maxTouchPoints && navigator.maxTouchPoints > 2) {
          isMobile = true;
        }
      }
      if (isMobile) {
        platform = "MobWeb";
      } else {
        platform = "Web";
      }
      return platform;
    },

    getPlatformType() {
      var unknown = "-";

      // browser
      var nVer = navigator.appVersion;
      var nAgt = navigator.userAgent;
      var browser = navigator.appName;
      var version = "" + parseFloat(navigator.appVersion);
      var majorVersion = parseInt(navigator.appVersion, 10);
      var nameOffset, verOffset, ix;

      // Opera
      if ((verOffset = nAgt.indexOf("Opera")) != -1) {
        browser = "Opera";
        version = nAgt.substring(verOffset + 6);
        if ((verOffset = nAgt.indexOf("Version")) != -1) {
          version = nAgt.substring(verOffset + 8);
        }
      }
      // Opera Next
      if ((verOffset = nAgt.indexOf("OPR")) != -1) {
        browser = "Opera";
        version = nAgt.substring(verOffset + 4);
      }
      // Edge
      else if ((verOffset = nAgt.indexOf("Edge")) != -1) {
        browser = "Microsoft Edge";
        version = nAgt.substring(verOffset + 5);
      }
      // MSIE
      else if ((verOffset = nAgt.indexOf("MSIE")) != -1) {
        browser = "Microsoft Internet Explorer";
        version = nAgt.substring(verOffset + 5);
      }
      // Chrome
      else if ((verOffset = nAgt.indexOf("Chrome")) != -1) {
        browser = "Chrome";
        version = nAgt.substring(verOffset + 7);
      }
      // Safari
      else if ((verOffset = nAgt.indexOf("Safari")) != -1) {
        browser = "Safari";
        version = nAgt.substring(verOffset + 7);
        if ((verOffset = nAgt.indexOf("Version")) != -1) {
          version = nAgt.substring(verOffset + 8);
        }
      }
      // Firefox
      else if ((verOffset = nAgt.indexOf("Firefox")) != -1) {
        browser = "Firefox";
        version = nAgt.substring(verOffset + 8);
      }
      // MSIE 11+
      else if (nAgt.indexOf("Trident/") != -1) {
        browser = "Microsoft Internet Explorer";
        version = nAgt.substring(nAgt.indexOf("rv:") + 3);
      }
      // Other browsers
      else if ((nameOffset = nAgt.lastIndexOf(" ") + 1) < (verOffset = nAgt.lastIndexOf("/"))) {
        browser = nAgt.substring(nameOffset, verOffset);
        version = nAgt.substring(verOffset + 1);
        if (browser.toLowerCase() == browser.toUpperCase()) {
          browser = navigator.appName;
        }
      }
      // trim the version string
      if ((ix = version.indexOf(";")) != -1) version = version.substring(0, ix);
      if ((ix = version.indexOf(" ")) != -1) version = version.substring(0, ix);
      if ((ix = version.indexOf(")")) != -1) version = version.substring(0, ix);

      majorVersion = parseInt("" + version, 10);
      if (isNaN(majorVersion)) {
        version = "" + parseFloat(navigator.appVersion);
        majorVersion = parseInt(navigator.appVersion, 10);
      }

      // mobile version
      var mobile = /Mobile|mini|Fennec|Android|iP(ad|od|hone)/.test(nVer);

      // cookie
      var cookieEnabled = navigator.cookieEnabled ? true : false;

      if (typeof navigator.cookieEnabled == "undefined" && !cookieEnabled) {
        document.cookie = "testcookie";
        cookieEnabled = document.cookie.indexOf("testcookie") != -1 ? true : false;
      }

      // system
      var os = unknown;
      var clientStrings = [
        { s: "Windows 10", r: /(Windows 10.0|Windows NT 10.0)/ },
        { s: "Windows 8.1", r: /(Windows 8.1|Windows NT 6.3)/ },
        { s: "Windows 8", r: /(Windows 8|Windows NT 6.2)/ },
        { s: "Windows 7", r: /(Windows 7|Windows NT 6.1)/ },
        { s: "Windows Vista", r: /Windows NT 6.0/ },
        { s: "Windows Server 2003", r: /Windows NT 5.2/ },
        { s: "Windows XP", r: /(Windows NT 5.1|Windows XP)/ },
        { s: "Windows 2000", r: /(Windows NT 5.0|Windows 2000)/ },
        { s: "Windows ME", r: /(Win 9x 4.90|Windows ME)/ },
        { s: "Windows 98", r: /(Windows 98|Win98)/ },
        { s: "Windows 95", r: /(Windows 95|Win95|Windows_95)/ },
        { s: "Windows NT 4.0", r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
        { s: "Windows CE", r: /Windows CE/ },
        { s: "Windows 3.11", r: /Win16/ },
        { s: "Android", r: /Android/ },
        { s: "Open BSD", r: /OpenBSD/ },
        { s: "Sun OS", r: /SunOS/ },
        { s: "Linux", r: /(Linux|X11)/ },
        { s: "iOS", r: /(iPhone|iPad|iPod)/ },
        { s: "Mac OS X", r: /Mac OS X/ },
        { s: "Mac OS", r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
        { s: "QNX", r: /QNX/ },
        { s: "UNIX", r: /UNIX/ },
        { s: "BeOS", r: /BeOS/ },
        { s: "OS/2", r: /OS\/2/ },
        { s: "Search Bot", r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/ },
      ];
      for (var id in clientStrings) {
        var cs = clientStrings[id];
        if (cs.r.test(nAgt)) {
          os = cs.s;
          break;
        }
      }

      var osVersion = unknown;

      if (/Windows/.test(os)) {
        osVersion = /Windows (.*)/.exec(os)[1];
        os = "Windows";
      }

      switch (os) {
        case "Mac OS X":
          osVersion = /Mac OS X (10[\.\_\d]+)/.exec(nAgt)[1];
          break;

        case "Android":
          osVersion = /Android ([\.\_\d]+)/.exec(nAgt)[1];
          break;

        case "iOS":
          osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer);
          osVersion = osVersion[1] + "." + osVersion[2] + "." + (osVersion[3] | 0);
          break;
      }

      // NOTE added device make model
      var nAgt = undefined;
      nAgt = navigator.userAgent;
      let device = "";
      let device_make = null;
      let device_model = null;
      if (nAgt) {
        // Nexus Phones
        if (nAgt.indexOf("Nexus") !== -1) {
          device = nAgt.substring(nAgt.indexOf("Nexus"), nAgt.indexOf(")"));
          device_make = "LG";
          device_model = device.substr(0, device.indexOf("Build"));
        }
        // Nokia Lumia Phones
        else if (nAgt.indexOf("Lumia") !== -1) {
          device_make = "Nokia";
          device = device_make + " " + nAgt.substring(nAgt.indexOf("Lumia"), nAgt.indexOf(")"));
          device_model = device;
        }
        // Nokia N-Series
        else if (nAgt.indexOf("NokiaN") !== -1) {
          device = nAgt.substring(nAgt.indexOf("NokiaN"), nAgt.indexOf(")"));
          device_make = "Nokia";
          device_model = device_make + " " + device.substring(device_make.length);
        }
        // iPad
        else if (nAgt.indexOf("iPad") !== -1) {
          device = nAgt.substring(nAgt.indexOf("iPad"), nAgt.indexOf(";"));
          device_make = "Apple";
          device_model = device_make + " " + device;
        }
        // iPhone
        else if (nAgt.indexOf("iPhone") !== -1) {
          device = nAgt.substring(nAgt.indexOf("iPhone"), nAgt.indexOf(";"));
          device_make = "Apple";
          device_model = device_make + " " + device;
        }
        // Pixel
        else if (nAgt.indexOf("Pixel") !== -1) {
          device = nAgt.substring(nAgt.indexOf("Pixel"), nAgt.indexOf(")"));
          device_make = "Google";
          // device_model = device.substr(0, device.indexOf(' ', device.indexOf(' ') + 1));
          device_model = device.substr(0, device.indexOf("Build"));
        }
        // Blackberry
        if (nAgt.indexOf("BB") !== -1) {
          device = nAgt.substring(nAgt.indexOf("BB"), nAgt.indexOf(";"));
          device_make = "BlackBerry";
          device_model = device_make + " " + device;
        }
        // Blackberry Playbook
        else if (nAgt.indexOf("PlayBook") !== -1) {
          device = nAgt.substring(nAgt.indexOf("PlayBook"), nAgt.indexOf(";"));
          device_make = "BlackBerry";
          device_model = device_make + " " + device;
        }
        // Kindle Fire
        if (nAgt.indexOf("KFAPWI") !== -1) {
          device = nAgt.substring(nAgt.indexOf("KFAPWI"), nAgt.indexOf(")"));
          device_make = "Kindle";
          device_model = device_make + " " + device.substr(0, device.indexOf("Build"));
        }
        // LG
        else if (nAgt.indexOf("LG") !== -1) {
          device = nAgt.substring(nAgt.indexOf("LG"), nAgt.indexOf(")"));
          device_make = "LG";
          device_model = device_make + " " + device.substr(0, device.indexOf("Build"));
        }
        // other mobile model
        else {
          var split_mobile_model = [];
          var split_mobile_model_again = [];
          if (nAgt.indexOf("Build") !== -1) {
            split_mobile_model = nAgt.split("Build")[0];
            split_mobile_model_again = split_mobile_model.split(";");
            device_model = split_mobile_model_again[split_mobile_model_again.length - 1];
            device_model = device_model.trim();
          }
        }
      }
      var flashVersion = "no check";
      if (typeof swfobject != "undefined") {
        var fv = swfobject.getFlashPlayerVersion();
        if (fv.major > 0) {
          flashVersion = fv.major + "." + fv.minor + " r" + fv.release;
        } else {
          flashVersion = unknown;
        }
      }

      return {
        browser: browser,
        browserVersion: version,
        browserMajorVersion: majorVersion,
        mobile: mobile,
        os: os,
        osVersion: osVersion,
        cookies: cookieEnabled,
        flashVersion: flashVersion,
        device_model: device_model,
      };
    },

    getCurrentTimeFrame() {
      let now = new Date();

      let hours = now.getUTCHours();

      let properTime;

      if (hours >= 0 && hours < 3) {
        properTime = "12am-3am";
      } else if (hours >= 3 && hours < 6) {
        properTime = "3am-6am";
      } else if (hours >= 6 && hours < 9) {
        properTime = "6am-9am";
      } else if (hours >= 9 && hours < 12) {
        properTime = "9am-12pm";
      } else if (hours >= 12 && hours < 15) {
        properTime = "12pm-3pm";
      } else if (hours >= 15 && hours < 18) {
        properTime = "3pm-6pm";
      } else if (hours >= 18 && hours < 21) {
        properTime = "6pm-9pm";
      } else if (hours >= 21 && hours < 24) {
        properTime = "9pm-12am";
      }

      console.log("PROPER TIME", properTime);

      return properTime;
    },

    getCurrentDayOfTheWeek() {
      const now = new Date();

      const date = now.getUTCDay();

      var gsDayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

      return gsDayNames[date];
    },

    getPaymentMode(mode) {
      let paymentMode;

      if (mode == "CHECKOUT") {
        paymentMode = "Checkout";
      } else if (mode == "PAYPALSUB") {
        paymentMode = "Paypal";
      } else {
        paymentMode = mode;
      }

      return paymentMode;
    },

    getPaymentErrorType(type) {
      let errorType;

      if (type == "APPROVAL_PENDING") {
        errorType = "User";
      } else {
        errorType = "Gateway";
      }

      return errorType;
    },

    getCurrentDisplayLanguage() {
      const language = localStorage.getItem("setDisplayLanguageCode");

      let displayLang;

      if (language == "eng") {
        displayLang = "English";
      } else if (language == "ara") {
        displayLang = "Arabic";
      }

      return displayLang;
    },

    validateAgeForProfile(dateString) {
      var today = new Date();
      var birthDate = new Date(dateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
      }
      return age;

    },
    
    throttleFunction(func, delay) {
      // Previously called time of the function
      let prev = 0;
      return (...args) => {
        // Current called time of the function
        let now = new Date().getTime();
  
        // If difference is greater than delay call
        // the function again.
        if (now - prev > delay) {
          prev = now;
  
          // "..." is the spread operator here
          // returning the function with the
          // array of arguments
          return func(...args);
        }
      };
    },

    debounceFunction(func, timeout = 1000){
      let timer;
      return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
      };
    },
    getCurrentLanguageUrlBase: function() {
      let lang = localStorage.getItem("setDisplayLanguageCode");
      let defaultLanguage = window.PROVIDER_SETUP_CONFIG.defaultLanguage;
      let supportedLanguages = window.PROVIDER_SETUP_CONFIG.supportLanguageList;

      if (lang === defaultLanguage) {
        return null;
      }
      for(let index = 0; index < supportedLanguages.length; index ++){
        let languageItem = supportedLanguages[index];
        if(lang === languageItem){
          return languageItem;
        }
      }
      return 'en';
      // if (lang === defaultLanguage) {
      //   return null;
      // }
      // for(let index = 0; index < supportedLanguages.length; index ++){
      //   let languageItem = supportedLanguages[index];
      //   if(lang === languageItem){
      //     return languageItem;
      //   }
      // }
      return lang == "hi" ? lang : null ;
    },
    getCurrentProfile: () => {
      const currentProfile = localStorage.getItem('subscriberProfileDetails') ? JSON.parse(localStorage.getItem('subscriberProfileDetails')) : '';
    
      const profileList = localStorage.getItem('profile-list') ? JSON.parse(localStorage.getItem('profile-list')) : '';
      for (let pro in profileList) {
        if (profileList[pro].profileid == currentProfile.profileid) {
          return profileList[pro];
        }
      }
    },
    isContentPlayableParentalLock: (currentProfile, ageRating) => {
      let isenabled = localStorage.getItem("parentalControlEnabled") == 'true' ? true : false;
      if (!currentProfile || !isenabled) return true;

      const ageRatingList = {
        'U': ['U'],
        'U/A 7+': ['U', 'U/A 7+'],
        'U/A 12+': ['U', 'U/A 7+', 'U/A 12+'],
        'U/A 13+': ['U', 'U/A 7+', 'U/A 12+', 'U/A 13+'],
        'U/A 16+': ['U', 'U/A 7+', 'U/A 12+', 'U/A 13+', 'U/A 16+'],
        'A': ['U', 'U/A 7+', 'U/A 12+', 'U/A 13+', 'U/A 16+', 'A'],
        'U/A 18+': ['U', 'U/A 7+', 'U/A 12+', 'U/A 13+', 'U/A 16+', 'A', 'U/A 18+']
      };
    
      for (let rating in ageRatingList) {
        if (rating == currentProfile.pgratting) {
           return ageRatingList[rating].includes(ageRating)
      
        }
      }
    },
    spacialCharEncoding(str) {
      if(!str) return;
      let string = str.replace(/-/g, ''); // Remove existing hyphens
      var i = string.length,
       savedContented = [];

      while (i--) {
          var iC = string[i].charCodeAt();
          if ((iC >= 48 && iC <= 57) || (iC >= 97 && iC <= 122) || (iC >= 65 && iC <= 90)) {
            savedContented[i] = (string[i]).toLowerCase();
          } else if (iC == 32) {
            savedContented[i] = '-';
          } else {
            savedContented[i] = '';
          }
      }
      return savedContented.join('').replace(/-+/g, '-');
    },
    metaData(){
      return {"Hindi-movies" : {
        "title": "Latest Hindi Movies | Watch Old & New Hindi Films Online in HD",
        "twitter:title": "Latest Hindi Movies | Watch Old & New Hindi Films Online in HD",
        "og:title": "Latest Hindi Movies | Watch Old & New Hindi Films Online in HD",
        "keywords": "Hindi movies, Hindi film, Hindi movie trailers, watch Hindi movies online, Hindi dubbed movies, Hindi dubbed films, old Hindi movies, old Hindi films, Hindi movies list online, best Hindi movies, top Hindi movies, Hindi full movies, latest Hindi movies, new Hindi movies, latest Hindi films, new Hindi films,",
        "description": "Watch latest Hindi Movies, trailers online in HD on Ultra Play. Stream best collection of old & new Hindi Films. Enjoy dubbed full Hindi Movies Now!",
        "og:description": "Watch latest Hindi Movies, trailers online in HD on Ultra Play. Stream best collection of old & new Hindi Films. Enjoy dubbed full Hindi Movies Now!",
        "twitter:description": "Watch latest Hindi Movies, trailers online in HD on Ultra Play. Stream best collection of old & new Hindi Films. Enjoy dubbed full Hindi Movies Now!"
    },
    "Hindi-shows" : {
        "title": "Watch Hindi Web Series, Hindi Natak, TV Serials & Shows Online",
        "twitter:title": "Watch Hindi Web Series, Hindi Natak, TV Serials & Shows Online",
        "og:title": "Watch Hindi Web Series, Hindi Natak, TV Serials & Shows Online",
        "keywords": "Hindi web series, Hindi series, Hindi shows, Hindi serials, Hindi natak, superhit Hindi natak, Hindi drama, Hindi natak online, Hindi web series online, best Hindi series, latest Hindi shows, new Hindi shows, old Hindi shows, old Hindi tv serials, Hindi tv shows, Hindi tv serials, Hindi tv series,",
        "description": "Watch Hindi Web Series, superhit comedy Hindi Natak online on Ultra Play. Stream your favorite old Hindi Drama, episodes of best TV Serials and latest Shows Now!",
        "og:description": "Watch Hindi Web Series, superhit comedy Hindi Natak online on Ultra Play. Stream your favorite old Hindi Drama, episodes of best TV Serials and latest Shows Now!",
        "twitter:description": "Watch Hindi Web Series, superhit comedy Hindi Natak online on Ultra Play. Stream your favorite old Hindi Drama, episodes of best TV Serials and latest Shows Now!"
    },
    "Hindi-music-video-songs" : {
        "title": "Watch Latest Hindi Video Songs, Play Hindi Music Album Videos Online",
        "twitter:title": "Watch Latest Hindi Video Songs, Play Hindi Music Album Videos Online",
        "og:title": "Watch Latest Hindi Video Songs, Play Hindi Music Album Videos Online",
        "keywords": "Hindi video songs, Hindi songs, latest Hindi songs, Hindi music video, new Hindi video song, Hindi hd video song, Hindi movie song, Hindi movie video song, Hindi romantic songs, koligeet, bhakti geet,",
        "description": "Watch latest Hindi Songs online on Ultra Play. Listen/Play album of superhit Hindi Music Video Song, new Hindi Movie Romantic Songs, Koligeet, Bhakti Geet in HD.",
        "og:description": "Watch latest Hindi Songs online on Ultra Play. Listen/Play album of superhit Hindi Music Video Song, new Hindi Movie Romantic Songs, Koligeet, Bhakti Geet in HD.",
        "twitter:description": "Watch latest Hindi Songs online on Ultra Play. Listen/Play album of superhit Hindi Music Video Song, new Hindi Movie Romantic Songs, Koligeet, Bhakti Geet in HD."
    },
    "Hindi-kids" : {
        "title": "Ultra Play: Hindi OTT Platform - Watch Movies, Kids Shows & More",
        "twitter:title": "Ultra Play: Hindi OTT Platform - Watch Movies, Kids Shows & More",
        "og:title": "Ultra Play: Hindi OTT Platform - Watch Movies, Kids Shows & More",
        "keywords": "",
        "description": "",
        "og:description": "",
        "twitter:description": ""
    },
    "Hindi-short-video-bytes" : {
        "title": "Ultra Play: Hindi OTT Platform - Watch Movies, Kids Shows & More",
        "twitter:title": "Ultra Play: Hindi OTT Platform - Watch Movies, Kids Shows & More",
        "og:title": "Ultra Play: Hindi OTT Platform - Watch Movies, Kids Shows & More",
        "keywords": "",
        "description": "",
        "og:description": "",
        "twitter:description": ""
    },
    "Hindi-devotional-videos" : {
        "title": "Ultra Play: Hindi OTT Platform - Watch Movies, Kids Shows & More",
        "twitter:title": "Ultra Play: Hindi OTT Platform - Watch Movies, Kids Shows & More",
        "og:title": "Ultra Play: Hindi OTT Platform - Watch Movies, Kids Shows & More",
        "keywords": "",
        "description": "",
        "og:description": "",
        "twitter:description": ""
    }
    };
    },

    generateVMapString(content, pageName) {
      let adConfigurations = this.appConfig.adConfigurtionsNew;
      if (!adConfigurations) return {};
      
      let category = content.category ? content.category.toUpperCase() : 'DEFAULT';
      let duration = content.duration;
      let adsList = adConfigurations[category] ? adConfigurations[category] : adConfigurations["DEFAULT"];
      let cuePointList = [];
      let sendBackData = {};

      let adRules = adsList.adRules;
      let adTags = adsList.adTags;
      let selectedRules = [];

      for (let item of adRules) {
        if (item.duration.start < duration && ((item.duration.end > duration) || (item.duration.end == -1))) {
          selectedRules = item;
          break;
        }
      }
      if ( selectedRules.excludeGenres && selectedRules.excludeGenres.includes(content.genre)) {
        return;
      }

      let vmapTag = '<vmap:VMAP xmlns:vmap="http://www.iab.net/videosuite/vmap" version="1.0.1">';
      if(selectedRules.adTypes.includes('pre')) {
        let numberOfAds = selectedRules.numberOfAds['pre'];
        let itemcheck = 0;
        // for (let i = 0; i < numberOfAds; i++) {
          let strtTime = this.convertMinutesToTime(itemcheck);

          cuePointList.push(itemcheck);
          itemcheck = itemcheck + 5;
          let preRollAdUrl = adTags['pre'];
          let preRollAdBreakTag = `<vmap:AdBreak timeOffset="${strtTime}" breakType="linear"><vmap:AdSource><vmap:AdTagURI><![CDATA[${preRollAdUrl}]]></vmap:AdTagURI></vmap:AdSource><vmap:TrackingEvents/></vmap:AdBreak>`;
          vmapTag = vmapTag + preRollAdBreakTag;

        // }
    

      }
      if (selectedRules.adTypes.includes('mid')) {
        let numberOfAds = selectedRules.numberOfAds['mid'];
        let breakTime = Number(duration) / (numberOfAds + 1);
        for (let x=1; x <= numberOfAds; x++) {
          let midTime = this.convertMinutesToTime(breakTime*x);
          cuePointList.push(breakTime*x);
          console.log('midTime', midTime);
          let midRollAdUrl = adTags['mid'];
          let midMdBreakTag = `<vmap:AdBreak timeOffset="${midTime}" breakType="linear"><vmap:AdSource><vmap:AdTagURI><![CDATA[${midRollAdUrl}]]></vmap:AdTagURI></vmap:AdSource><vmap:TrackingEvents/></vmap:AdBreak>`;
          vmapTag = vmapTag + midMdBreakTag;
        }
      }
      if (selectedRules.adTypes.includes('post')) {      
        cuePointList.push(Number(duration) - 5);
        let endTime = this.convertMinutesToTime(Number(duration) - 5);  
        let postRollAdUrl = adTags['post'];
        let postAdBreakTag = 	`<vmap:AdBreak timeOffset="${endTime}" breakType="linear"><vmap:AdSource><vmap:AdTagURI><![CDATA[${postRollAdUrl}]]></vmap:AdTagURI></vmap:AdSource><vmap:TrackingEvents/></vmap:AdBreak>`;
        vmapTag = vmapTag + postAdBreakTag;
      }
      
      vmapTag = vmapTag + '</vmap:VMAP>';
      sendBackData.vmapTag = vmapTag;
      sendBackData.cuePointList = cuePointList;

      return sendBackData;

    },
    convertMinutesToTime(SECONDS) {
      return new Date(SECONDS * 1000).toISOString().substring(11, 19);
    },
    actMetaData(type) {
      let metaData = null;
      console.log('metaData', type);
      fetch('/meta.json').then((res)=> res.json()).then((result) => {
            metaData = result ? result[type] : undefined;
            if (metaData) {
              var allMetaElements = document.getElementsByTagName('meta');
              document.title = metaData?.title ? metaData?.title : "Ultra Play OTT: Watch Hindi Movies - South Indian Dubbed, Bollywood Films";
              for (var metaValue = 0; metaValue < allMetaElements.length; metaValue++) {
                if (allMetaElements[metaValue].getAttribute("name") == "description") {
                  allMetaElements[metaValue].setAttribute('content', metaData?.description ? metaData?.description : '');
                }
      
                if (allMetaElements[metaValue].getAttribute("property") == "og:description") {
                  allMetaElements[metaValue].setAttribute('content', metaData?.description ? metaData?.description : '');
                }
                if (allMetaElements[metaValue].getAttribute("name") == "twitter:description") {
                  allMetaElements[metaValue].setAttribute('content', metaData?.description ? metaData?.description : '');
                }
                if (allMetaElements[metaValue].getAttribute("name") == "keyword") {
                  allMetaElements[metaValue].setAttribute('content', metaData?.keyword ? metaData?.keyword : '');
                }
                if (allMetaElements[metaValue].getAttribute("property") == "og:title") {
                  allMetaElements[metaValue].setAttribute('content', metaData?.title ? metaData?.title : '');
                }
                if (allMetaElements[metaValue].getAttribute("name") == "twitter:title") {
                  allMetaElements[metaValue].setAttribute('content', metaData?.title ? metaData?.title : '');
                }
              }
            }
      });
      let metaOgImage = 'https://static-templates.firebaseapp.com/img/ultraplay_200x200.jpg';
      document.querySelector('meta[property="og\\:image"]').setAttribute('content', metaOgImage);
      document.querySelector('meta[property="og\\:image\\:width"]').setAttribute('content', 200);
      document.querySelector('meta[property="og\\:image\\:height"]').setAttribute('content', 200);
    },
    
  },
  


};

export default Utility;
